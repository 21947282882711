import React, { useState } from 'react';
import './contact.css'; // Import the external CSS file
import { motion } from 'framer-motion';
import * as emailjs from 'emailjs-com';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required.';
    if (!formData.email) {
      errors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid.';
    }
    if (!formData.message) errors.message = 'Message is required.';
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      
      // Sending email using EmailJS
      const serviceID = 'service_wfab0qf';
      const templateID = 'template_dvjmwtg';
      const userID = 'xQELRyVXuLLJ-Tong';

      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        message: formData.message
      };

      emailjs
        .send(serviceID, templateID, templateParams, userID)
        .then(
          (response) => {
            console.log('Email sent successfully!', response.status, response.text);
            setIsSubmitted(true);
            setFormData({
              name: '',
              email: '',
              message: ''
            });
          },
          (error) => {
            console.error('Failed to send email:', error);
            alert('Failed to send email. Please try again later.');
          }
        );
    }
  };

  return (
    <section className="contact-section">
      <div className="contact-container">
        <div className="highlight-section mt-12 mb-12 bg-blue-100 p-6 rounded-lg shadow-xl">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
          >
            <h2 className="text-3xl font-bold text-blue-800 mb-4">Home Visits within Vadodara</h2>
            <p className="text-blue-700">
              I provide personalized physiotherapy care directly at your home, ensuring maximum
              comfort and convenience for our patients within Vadodara.
            </p>
          </motion.div>
        </div>

        <div className="contact-content">
          <div className="left-section">
            <h1>Get in Touch with Dr. Moksha Patel (PT)</h1>
            <p>
              Have questions about physiotherapy or the services I offer? Feel free to reach out!
              I'm here to assist you in your journey to better health and mobility.
            </p>
            <div className="contact-info mt-6">
              <p className="font-semibold text-lg">Contact Information</p>
              <p>
                <strong>Email:</strong>{' '}
                <a
                  href="mailto:moxapatel1908@gmail.com"
                  className="text-blue-200 hover:text-blue-400"
                >
                  moxapatel1908@gmail.com
                </a>
              </p>
              <p>
                <strong>Phone:</strong>{' '}
                <a
                  href="tel:+916353937571"
                  className="text-blue-200 hover:text-blue-400"
                >
                  +91 6353937571
                </a>
              </p>
            </div>
          </div>

          <div className="right-section">
            <div className="contact-form-container">
              <h1 className="text-white">Contact Me</h1>
              {isSubmitted && (
                <p className="success-message text-green-500">
                  Thank you! Your message has been sent successfully.
                </p>
              )}
              <form onSubmit={handleSubmit}>
                <div className="flex-1">
                  <label className="block mt-2 text-sm text-white" htmlFor="name">
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Your Full Name"
                    value={formData.name}
                    onChange={handleChange}
                    className={formErrors.name ? 'error' : ''}
                  />
                  {formErrors.name && <p className="error-message">{formErrors.name}</p>}
                </div>

                <div className="flex-1 mt-6">
                  <label className="block mt-2 text-sm text-white" htmlFor="email">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="someone@example.com"
                    value={formData.email}
                    onChange={handleChange}
                    className={formErrors.email ? 'error' : ''}
                  />
                  {formErrors.email && <p className="error-message">{formErrors.email}</p>}
                </div>

                <div className="w-full mt-6">
                  <label className="block mt-2 text-sm text-white" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Your message here"
                    className={formErrors.message ? 'error' : ''}
                  ></textarea>
                  {formErrors.message && <p className="error-message">{formErrors.message}</p>}
                </div>

                <button type="submit" className="mt-4">
                  {isSubmitted ? 'Sent' : 'Send Message'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
